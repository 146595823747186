import { AUTH_TOKEN } from './authenticationHelper/authConstants';

export const clearCache = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('oidc')) {
      localStorage.removeItem(key);
    }
  }
  localStorage.removeItem(AUTH_TOKEN);
};

export const checkAppVersion = (currentVersion: string | undefined) => {
  const storedVersion = localStorage.getItem('appVersion');
  if (currentVersion) {
    // If no version is found in local storage (first time use) or the version has changed
    if (!storedVersion || storedVersion !== currentVersion) {
      // Clear local storage and store the new version
      clearCache();
      localStorage.setItem('appVersion', currentVersion);
    }
  }
};
